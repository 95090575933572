var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "get-spot-loads" },
    [
      _c("v-data-table", {
        attrs: {
          dense: "",
          headers: _vm.loadHeaders,
          items: _vm.loads,
          "item-key": "item.loadId",
          loading: _vm.loading,
        },
        on: { "click:row": _vm.MoveToDetail },
        scopedSlots: _vm._u([
          {
            key: "item.origin",
            fn: function ({ item }) {
              return [
                _c("p", [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      _vm._s(item.origin.originCity) +
                        ", " +
                        _vm._s(item.origin.originState)
                    ),
                  ]),
                  _c(
                    "span",
                    { staticClass: "subtitle" },
                    [
                      _c("date-formatter", {
                        attrs: { date: item.origin.pickupDate },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
          {
            key: "item.destination",
            fn: function ({ item }) {
              return [
                _c("p", [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      _vm._s(item.destination.destCity) +
                        ", " +
                        _vm._s(item.destination.destState)
                    ),
                  ]),
                  _c(
                    "span",
                    { staticClass: "subtitle" },
                    [
                      _c("date-formatter", {
                        attrs: { date: item.destination.deliveryDate },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
          {
            key: "item.dateCreated",
            fn: function ({ item }) {
              return [
                _c("date-formatter", { attrs: { date: item.dateCreated } }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }