var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "carrier-matches" } },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.carriers,
          loading: _vm.loading,
          dense: "",
          "fixed-header": "",
        },
        on: { "click:row": _vm.openCarrierMatches },
        scopedSlots: _vm._u([
          {
            key: "header.averageRate",
            fn: function () {
              return [
                _c("span", [_vm._v("Average Rate ")]),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g({ attrs: { "x-small": "" } }, on),
                              [_vm._v(" mdi-information-outline ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("span", [
                      _vm._v("Average assigned rate or carrier bid."),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item.matchType",
            fn: function ({ item }) {
              return [
                item.matchType === 1
                  ? _c("v-icon", [_vm._v(" mdi-history ")])
                  : _vm._e(),
                item.matchType === 2
                  ? _c("v-icon", [_vm._v(" mdi-account ")])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.averageRate",
            fn: function ({ item }) {
              return [
                item.averageRate !== null
                  ? [_vm._v(" $" + _vm._s(item.averageRate) + " ")]
                  : item.averageBid !== null
                  ? [_vm._v(" $" + _vm._s(item.averageBid) + " ")]
                  : [_vm._v(" -- ")],
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }